import React from 'react'

import { useRouter } from 'next/router'

import { useApp, path, functions } from '@wap-client/core'
import { pageService } from '@wap-client/services'

import { Container, Row } from '@/components/base/gridview'
import IconList, { IconListItem } from '@/components/base/icon-list'
import Anchor from '@/components/base/anchor/Anchor'

import { FooterProps } from './types'
import Button from '@/components/base/button/Button'
import Icon from '@/components/base/icon'

const Footer: React.FunctionComponent<FooterProps> = ({
  navigation = [],
  policy = [],
}) => {
  const app = useApp()
  const router = useRouter()

  const SocialMedia = () => {
    return (
      <IconList className="social-media-list">
        <IconListItem
          anchor={{
            href: app.settings.socials['twitter'] || 'https://twitter.com/',
            target: '_blank',
          }}
          icon={{ name: 'twitter' }}
        />
        <IconListItem
          anchor={{
            href:
              app.settings.socials['facebook'] || 'https://www.facebook.com/',
            target: '_blank',
          }}
          icon={{ name: 'facebook' }}
        />
        <IconListItem
          anchor={{
            href:
              app.settings.socials['instagram'] || 'https://www.instagram.com/',
            target: '_blank',
          }}
          icon={{ name: 'instagram' }}
        />
        <IconListItem
          anchor={{
            href: app.settings.socials['youtube'] || 'https://www.youtube.com/',
            target: '_blank',
          }}
          icon={{ name: 'youtube' }}
        />
        <IconListItem
          anchor={{
            href: app.settings.socials['spotify'] || 'https://www.tiktok.com/',
            target: '_blank',
          }}
          icon={{ name: 'tik-tok' }}
        />
      </IconList>
    )
  }

  const handleLanguage = (language: string) => {
    pageService
      .findPath(app.environment, { path: app.page.path, language })
      .then((slug) => {
        router.push(path.app(app.environment, slug))
      })
      .catch(() => {
        const homepagePath = app.settings.homepage

        if (homepagePath) {
          pageService
            .findPath(app.environment, { path: homepagePath, language })
            .then((slug) => {
              router.push(path.app(app.environment, slug))
            })
        }
      })
  }

  const Language = () => {
    if (app.languages.length > 1) {
      return (
        <div className="navigation__language">
          <ul className="navigation__language__list">
            {app.languages.map((language, index) => (
              <li key={index}>
                <Button
                  size="none"
                  onClick={() => handleLanguage(language.id)}
                  className={functions.classnames(
                    app.language === language.name && 'current'
                  )}
                >
                  {language.name.substring(0, 2)}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      )
    }
  }

  const Navigation = () => {
    if (!navigation) return null

    return (
      <nav className="navigation">
        <div className="navigation__menu">
          <ul>
            {navigation.map((item, index) => (
              <li key={index}>
                <Anchor href={item.href}>{item.title}</Anchor>
              </li>
            ))}
          </ul>
        </div>
        {/* {Language()} */}
      </nav>
    )
  }

  const renderPolicy = () => {
    return (
      <nav className="policy">
        <ul className="policy-list">
          {policy.map((item, index) => (
            <li key={index} className="policy-list-item">
              <Anchor href={item.href}>{item.title}</Anchor>
            </li>
          ))}
        </ul>
      </nav>
    )
  }

  return (
    <footer className="footer">
      <Container size="extended">
        <Row>{SocialMedia()}</Row>
        <Row>{Navigation()}</Row>
        <Row>{renderPolicy()}</Row>
        <Row>
          <div className="footer__copyright">
            <span>{app.settings.translations['copyright']}</span>
            <span>{app.settings.translations['copyright-reserved']}</span>
          </div>
        </Row>
      </Container>

      <Button
        className="scrollTop"
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }}
      >
        <Icon name="arrow-up" />
      </Button>
    </footer>
  )
}

export default Footer
